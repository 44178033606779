import React, { ReactNode, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import RightSideBar from "../RithSideBar/RightSideBar";
import RightSideBar1 from "../RithSideBar/RightSideBar1";
import style from "./style.module.css";

import NewOrder from "../NewOrder/NewOrder";
import SideBarMobile from "../SideBar/SideBarMobile";
import Notifications from "../RithSideBar/Notifications";
import Profile from "../RithSideBar/Profile";
import RightSideBar2 from "../RithSideBar/RightSideBar2";
import Modal from "../Modal/Modal";
import axiosInstance from "../../../../setup/axios";
import Constants from "../../../../Utils/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import InfoOrder from "../NewOrder/InfoOrder";
import { orderInfoData } from "../NewOrder/types";
import Select from "react-select";
import userprofile from "../../../../Pages/Main/Components/SideBar/Assets/Icons/UserProfile.png";
import notifs from "../../../../Pages/Main/Components/SideBar/Assets/Icons/Notifications.png";
import { useQuery } from "@tanstack/react-query";

type PageWrapperProp = {
  content: ReactNode;
  rightSiderContent?: ReactNode;
};

//mobile
const PageWrapper = ({ content, rightSiderContent }: PageWrapperProp) => {
  const navigate = useNavigate();
  const location = useLocation();

  const moreSwapTransactions = [
    // without entire rightbar
    "/more-swap-transactions",
    "/more-wallet-transactions",
    "/send-bitcoin",
    "/new-order",
    "/buy",
    "/sell",
  ];
  // with rightbar without recent orders
  const swapPage = [
    "/swap",
    "/wallet",
    "/two-factor",
    "/settings",
    "/referral",
  ];

  const handleMoreSwap = moreSwapTransactions.includes(location.pathname);
  const handleSwap = swapPage.includes(location.pathname);

  const [openSelect] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const options = [
    { value: "buy", label: "Buy" },
    { value: "sell", label: "Sell" },
  ];

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);

    if (selectedOption.value === "sell") {
      navigate("/sell");
    } else if (selectedOption.value === "buy") {
      navigate("/buy");
    }
  };

  const selectStyles = {
    control: (styles: any, state: { isFocused: any }) => ({
      ...styles, // Spread existing styles
      backgroundColor: "#01847C",

      borderRadius: 10,

      // Set background color
    }),
    input: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    placeholder: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      color: "#FFFFFF",
    }),
  };

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#white",
    },
  });

  const [state, setState] = useState({
    isProfile: false,
    isNotification: false,
    isSideBar: false,
    isActionBtn: false,
    reverseAnimation: false,
    showNewOrder: false,
    showInfoOrder: {
      status: false,
      data: {},
    },
    amountError: "",
    hasPendingOrders: false,
  });

  useEffect(() => {
    if (state.reverseAnimation) {
      setTimeout(() => {
        setState({
          ...state,
          reverseAnimation: false,
          isNotification: false,
          isSideBar: false,
          isProfile: false,
        });
      }, 1100);
    }
    // eslint-disable-next-line
  }, [state.reverseAnimation]);

  useEffect(() => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/orders/has/pending`)
      .then((res) => {
        if (res.data) {
          setState({ ...state, hasPendingOrders: true });
        } else {
          setState({ ...state, hasPendingOrders: false });
        }
      });
    // eslint-disable-next-line
  }, []);

  const HasPending = () => {
    return (
      <div className="mt-4 pb-5 text-center flex flex-col">
        <div className="w-7 h-7 mb-2 rounded-full flex j-c-c bg-red-600 text-white">
          <i className="fa fa-info"></i>
        </div>
        You have a pending order <br />
        Please complete pending orders <br />
        <button
          onClick={() => {
            navigate("/orders");
            setState({ ...state, showNewOrder: false });
          }}
          className="mt-6 px-4 py-2 bg-theme text-white rounded-lg"
        >
          Complete or Cancel
        </button>
      </div>
    );
  };

  const closeNewOrder = (data: any) => {
    setState((prev) => {
      return {
        ...prev,
        showNewOrder: false,
        showInfoOrder: {
          status: true,
          data,
        },
      };
    });
  };

  const getNotifications = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/notifications/count`,
    );
    return response.data;
  };

  const { data: notificationCount } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
  });

  return (
    <div className={`w-screen h-screen flex a-i-s j-c-b bg-bg `}>
      <SideBar />
      {state.isSideBar && (
        <SideBarMobile
          isOn={state.reverseAnimation}
          close={() => {
            setState({ ...state, reverseAnimation: true });
            setState({ ...state, isSideBar: false });
          }}
        />
      )}

      <section
        className={`overflow-y-auto bg-white 2xl:px-[8%] xl:px-[5%] lg:px-[20px] md:px-[40px] px-[10px] py-5 lg:py-0 `}
        style={{ width: "100%", height: "100%" }}
      >
        <div className="w-full flex j-c-b items-center mb-5">
          <div
            onClick={() => setState({ ...state, isSideBar: true })}
            className={`flex cursor-pointer hover:text-theme ${style.menuIcon}`}
          >
            <svg
              className={`mr-2 w-7 h-7`}
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
              ></path>
            </svg>
            <p>Menu</p>
          </div>
          {/* <i onClick={()=>setState({...state, isSideBar: true})} className={`fa fa-bars ${style.menuIcon} mr-5 text-2xl `}></i> */}
          {/* <Search/> */}
          <br />
          <div
            className={`${style.orderButton} min-w-fit flex items-center ml-6`}
          >
            <div
              className="notify relative mx-2"
              onClick={() => setState({ ...state, isNotification: true })}
            >
              {notificationCount > 0 ? (
                <aside className="w-3 h-3 rounded-full bg-red-500 absolute top-0 right-0 flex items-center justify-center">
                  <div className="text-white text-[8px] font-bold">
                    {notificationCount}
                  </div>
                </aside>
              ) : null}{" "}
              <img src={notifs} alt="" width={25} />
            </div>
            <img
              src={userprofile}
              alt=""
              width={25}
              onClick={() => setState({ ...state, isProfile: true })}
            />
            {/* <Button
              text={"Buy or Sell"}
              onClick={() => setState({ ...state, showNewOrder: true })}
             
            /> */}
            {openSelect && (
              <div className="">
                {" "}
                <Select
                  defaultValue={selectedOption}
                  onChange={handleChange}
                  options={options}
                  placeholder="Buy or Sell"
                  styles={selectStyles}
                  theme={customeTheme}
                  isSearchable={false}
                  blurInputOnSelect={false}
                />
              </div>
            )}{" "}
          </div>
        </div>
        <div className="min-h-screen">{content}</div>

        {/* Footer ......... */}
        {/* className={`flex flex-col md:flex-row j-c-b h-fit text-gray-500 mt-[4rem] fixed  lg:fixed lg:bottom-[20px] lg:overflow-auto `} */}

        <div
          className="flex flex-col md:flex-row j-c-b h-fit text-gray-500 relative  "
          style={{ zIndex: 100 }}
        >
          <small className="">© 2023 HodlPay. All Rights Reserved</small>
          <div className="flex">
            <small className="mx-3">
              <a
                href="https://hodlpay.io/#about"
                target="_blank"
                rel="noreferrer"
              >
                About
              </a>
            </small>
            <small className="mx-3">
              <a
                href="https://hodlpay.io/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                T&Cs
              </a>
            </small>
            <small className="mx-3">
              <a
                href="https://hodlpay.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </small>
          </div>
        </div>
      </section>

      {!handleMoreSwap &&
        (handleSwap ? (
          // without recent orders
          <RightSideBar1 newContent={rightSiderContent} />
        ) : (
          <RightSideBar newContent={rightSiderContent} />
        ))}

      {state.isNotification && (
        <div className="z-10 w-full h-ful fixed top-0 right-0 bottom-0 left-0  flex justify-end items-start">
          <section
            onClick={() => {
              setState({ ...state, reverseAnimation: true });
              setState({ ...state, isNotification: false });
            }}
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Notifications
            isOn={state.reverseAnimation}
            hasNewNotifications={hasNewNotifications}
            setHasNewNotifications={setHasNewNotifications}
          />
        </div>
      )}
      {state.isProfile && (
        <div className="z-10 w-full h-ful fixed top-0 right-0 bottom-0 left-0  flex justify-end items-start">
          <section
            onClick={() => {
              setState({ ...state, reverseAnimation: true });
              setState({ ...state, isProfile: false });
            }}
            className="w-full h-full grow bg-modalBg"
          ></section>
          <Profile isOn={state.reverseAnimation} />
        </div>
      )}

      {/* {
                !state.isActionBtn && <button onClick={()=>setState({...state, isActionBtn: true})} style={{boxShadow: '0 2px 8px 1px rgb(0,0,0,.5)'}} className={`${style.actionBtn} flex fixed -right-[12px] animatefromright bottom-[10vh] px-5 py-4 rounded-l-[20px]  bg-theme text-white font-semibold`}>
                    <i className="fa fa-wallet mr-2"></i>
                    <p>Wallet History</p>
                </button>
            } */}

      {state.isActionBtn && (
        <RightSideBar2
          close={() => setState({ ...state, isActionBtn: false })}
        />
      )}

      {state.showNewOrder && (
        <Modal
          close={() => setState({ ...state, showNewOrder: false })}
          content={
            state.hasPendingOrders ? (
              <HasPending />
            ) : (
              <NewOrder close={(data: any) => closeNewOrder(data)} />
            )
          }
        />
      )}

      {state.showInfoOrder.status && (
        <Modal
          close={() =>
            setState({ ...state, showInfoOrder: { status: false, data: {} } })
          }
          content={
            <InfoOrder
              close={() =>
                setState({
                  ...state,
                  showInfoOrder: { status: false, data: {} },
                })
              }
              orderInfo={state.showInfoOrder.data as orderInfoData}
            />
          }
        />
      )}
    </div>
  );
};

export default PageWrapper;
