import React, { useState } from "react";
import btc3d from "../../../../../Common/Assets/Images/btc3d.png";
import usdt3d from "../../../../../Common/Assets/Images/usdt3d.png";
import wallet from "../../../../../Common/Assets/Icons/wallet.png";
import style from "./style.module.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

type props = {
  stats: {
    totalBuyOrders?: number;
    totalBuyOrdersAmount?: number;
    totalSellOrders?: number;
    totalSellOrdersAmount?: number;
  };
};

function UserStats({ stats }: props) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: "buy", label: "Buy" },
    { value: "sell", label: "Sell" },
  ];

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    if (selectedOption.value === "sell") {
      navigate("/sell");
    } else if (selectedOption.value === "buy") {
      navigate("/buy");
    }
  };

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "#FFFFFF",
      borderRadius: 10,
    }),
    input: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
    placeholder: (styles: any) => ({ ...styles, color: "#00000" }),
    singleValue: (styles: any) => ({ ...styles, color: "#FFFFFF" }),
  };

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#white",
    },
  });

  return (
    <div
      className={`${style.userStats} flex a-i-s p-5 rounded-lg h-[280px] border-[1px] border-[#00332546] bg-[#f8f8ff39]`}
      style={{ boxShadow: "0 0 8px 1px rgb(0,0,0,.1)", maxWidth: "560px" }} // Adjusted width here
    >
      <div
        className={`${style.img} flex v-flex j-c-c bg-theme px-5 py-12 rounded-2xl mr-5 h-full`}
      >
        <div
          className="flex relative m-auto"
          style={{ width: "180px", height: "100px" }}
        >
          <img className="absolute left-0" src={btc3d} alt="" width={120} />
          <img className="absolute right-0" src={usdt3d} alt="" width={120} />
        </div>
        <div className="ml-4">
          <Select
            defaultValue={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Buy or Sell"
            styles={selectStyles}
            theme={customeTheme}
            isSearchable={false}
            blurInputOnSelect={false}
          />
        </div>
      </div>
      <div
        className={`${style.stats} flex flex-col h-full justify-between px-3`}
      >
        <section className="flex j-c-b rounded-xl border-[1px] border-[#00805ea1] bg-[#b8ffed18] shadow p-3 mb-2 h-[85%]">
          <div className="flex v-flex mx-7">
            <img src={wallet} alt="" />
            <p>Buy Orders</p>
          </div>
          <div
            style={{ height: "80%", width: "2px", background: "#00805ea1" }}
          ></div>
          <div className="flex v-flex mx-7">
            <h1 className="text-2xl font-semibold">
              ${stats.totalBuyOrdersAmount}
            </h1>
            <small className="text-gray-400">
              {stats.totalBuyOrders} Total buy orders
            </small>
          </div>
        </section>
        <section className="flex j-c-b rounded-xl border-[1px] border-[#ab030099] bg-[#ffc8c819] shadow p-3 mt-2 h-[85%]">
          <div className="flex v-flex mx-7">
            <i className="fa fa-wallet text-3xl text-red-500"></i>
            <p>Sell Orders</p>
          </div>
          <div
            style={{ height: "80%", width: "2px", background: "#ab030099" }}
          ></div>
          <div className="flex v-flex mx-7">
            <h1 className="text-2xl font-semibold">
              ${stats.totalSellOrdersAmount}
            </h1>
            <small className="text-gray-400">
              {stats.totalSellOrders} Total sell orders
            </small>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UserStats;

export const color = () => {
  let sections = [20, 50, 100, 150, 200, 222];
  return `rgba(
                ${sections[Math.floor(Math.random() * 6)]},
                ${sections[Math.floor(Math.random() * 6)]},
                ${sections[Math.floor(Math.random() * 6)]}, .4
            )`;
};
